html,
body {
  overflow-x: hidden;
}

@font-face {
  font-family: "pirulenregular";
  src: url("/public/fonts/pirulen_rg-webfont.woff2") format("woff2"),
    url("/public/fonts/pirulen_rg-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
body {
  background-color: #091251 !important;
  position: relative;
}
/* body:before{
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  background-image: url('./images/body-bg.png');
  background-size: cover;

} */
.profile-heading {
  left: 51%;
  top: 80px;
  width: 100%;
  position: absolute;
  font-size: 12px;
  transform: translate(-50%);
  background: linear-gradient(270deg, #7f80df 0%, #f9bff9 48%, #6deef3 96%);
  margin-top: 70px;
  text-align: center;
  font-family: pirulenregular;
  font-weight: 400;
  margin-bottom: 20px;
  text-transform: uppercase;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mainContainer {
  min-height: calc(100vh - 350px);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.MuiStack-root {
  margin-top: 10px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}
#account-menu .MuiPaper-elevation {
  background-color: transparent !important;
  background-image: none !important;
}
.time-icon {
  height: 12px;
  width: 12px;
  margin-right: 5px;
}

.time-icon {
  margin-left: 5px;
  margin-right: 2px;
}
.css-1jzi3sl-MuiGrid-root > .MuiGrid-item {
  padding-top: 10px !important;
}
.css-1jzi3sl-MuiGrid-root {
  margin-top: 0 !important;
}
.card-sport-icon {
  height: 100%;
  width: 100%;
}
.match-card-border {
  position: relative;
}
.match-card-border:before {
  position: absolute;
  left: -30px;
  top: 0;
  width: 3px;
  height: 100px;
  content: "";
  background: #21f522;
}
.match-card-wrap {
  padding: 40px;
}
.match-card-wrap .match-card-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
}
.jackpotcta {
  position: relative;
  cursor: pointer;
  min-width: 100px;
  margin: 0 10px;
  top: -10px;
}
.jackpotcta img {
  width: 85px;
  position: absolute;
  transform: translate(-50%);
  left: 50%;
}
.btn {
  padding: 6px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}
.btn-primary {
  background: #8969d8;
  border: 1px solid #8969d8;
  color: #fff;
}
.btn-primary:hover {
  background: #8b5ef9;
  border-color: #8b5ef9;
}
.jackpotcta img:nth-child(2),
.jackpotcta:hover img:nth-child(1) {
  display: none;
}
.jackpotcta:hover img:nth-child(2) {
  display: block;
}
.disable-scroll {
  overflow: hidden;
}
.react-horizontal-scrolling-menu--inner-wrapper {
  position: relative;
}

.react-horizontal-scrolling-menu--arrow-left,
.react-horizontal-scrolling-menu--arrow-right {
  align-items: center;
  position: absolute;
  top: 36%;
  z-index: 1;
}
.react-horizontal-scrolling-menu--arrow-left {
  left: 0rem;
}
.react-horizontal-scrolling-menu--arrow-right {
  right: 1.5rem;
}
.react-horizontal-scrolling-menu--arrow-left .MuiButtonBase-root,
.react-horizontal-scrolling-menu--arrow-right .MuiButtonBase-root {
  height: 40px;
  width: 40px;
  background: #00134f;
  border: 1px solid #fff;
  border-radius: 100%;
  padding: 0;
  flex: 0 0 40px;
  position: relative;
  font-size: 0;
  min-width: 40px;
}
.react-horizontal-scrolling-menu--arrow-left .MuiButtonBase-root.Mui-disabled,
.react-horizontal-scrolling-menu--arrow-right .MuiButtonBase-root.Mui-disabled {
  opacity: 0;
}
.react-horizontal-scrolling-menu--arrow-left .MuiButtonBase-root:before,
.react-horizontal-scrolling-menu--arrow-right .MuiButtonBase-root:before {
  position: absolute;
  top: 8px;
  content: "";
  background: url("/public/images/left.png");
  background-repeat: no-repeat;
  background-size: 25px;
  height: 25px;
  width: 25px;
  display: inline-block;
  color: #fff;
  vertical-align: middle;
  font-weight: 900;
  font-size: 24px;
}
.react-horizontal-scrolling-menu--arrow-right .MuiButtonBase-root:before {
  background: url("/public/images/right.png");
  top: 7px !important;
  width: 25px;
  left: 10px;
  height: 24px;
  background-size: 25px;
}
.horizontalScrollWrapper {
  width: 100vw;
  padding-right: 100px;
  position: relative;
}
ul[role="listbox"] .MuiList-root .MuiMenu-list {
  background-color: #040a30;
}

/* @media (min-width: 1560px) {
  .profile-heading {
    top: 81px;
  }
} */
@media (min-width: 900px) and (max-width: 1500px) {
  .MuiTabs-scroller .MuiButtonBase-root {
    min-width: 65px;
    margin-right: 5px;
    font-size: 12px;
  }
  .MuiTabs-scroller .MuiButtonBase-root {
    min-width: 65px;
    margin-right: 5px;
    font-size: 10px;
  }
}
@media (max-width: 1199px) {
  .profile-heading {
    position: static;
    transform: none;
    margin: 0;
  }
}
@media (max-width: 991px) {
  /* .mob-view{
    display: none;
  } */
  /* .MuiTabs-scroller .MuiButtonBase-root{
    min-width: 70px;
    margin-right: 5px;
    font-size: 12px;
  } */
  .match-card-wrap {
    padding: 20px 15px;
  }
  .jackpotcta img {
    position: static;
    transform: none;
  }
}
@media (max-width: 899px) {
  .mob-view {
    display: none;
  }
}
@media (max-width: 767px) {
  .match-card-content .MuiGrid-item {
    padding: 0;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background: #39394a;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #8b5ef9 -4.89%, #8b5ef9 100%);
  border-radius: 10px;
}

.css-1if8r2c-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  background-color: #040a30 !important;
}
.react-horizontal-scrolling-menu--scroll-container {
  column-gap: 1rem;
}
@media (max-width: 767px) {
  .MuiGrid-item {
    padding-left: 10px !important;
  }
}
@media (max-width: 767px) {
  .horizontalScrollWrapper .MuiGrid-item {
    width: 150px !important;
    height: 100% !important;
  }
  .horizontalScrollWrapper {
    padding-right: 60px;
    width: 95vw;
  }
}
@media (max-width: 767px) {
  .react-horizontal-scrolling-menu--arrow-right {
    right: 0.5rem;
  }
}
